import { Button, Dialog } from 'evergreen-ui';
import React, { FC } from 'react';
import { GrClose } from 'react-icons/gr';

interface Props {
    showDelete: boolean;
    setShowDelete: (state: boolean) => void;
    deleteRecord: () => void
}

export const DeleteDialog: FC<Props> = ({ showDelete, setShowDelete, deleteRecord }) => {
    return (
        <div>
            <Dialog
                isShown={showDelete}
                title='Delete Record'
                minHeightContent={0}
                intent='danger'
                confirmLabel='Delete'
                hasHeader={false}
                hasFooter={false}
                onCloseComplete={() => setShowDelete(false)}
            >
                {({ close }) => (
                    <>
                        <div className='flex justify-between pt-3'>
                            <h3 className='text-red-600 font-semibold'>Delete Record</h3>
                        </div>
                        <p >Are your sure want to delete?</p>
                        <div className='flex flex-row-reverse pb-3'>
                            <Button marginLeft={10} intent='danger' appearance='primary' onClick={deleteRecord}>Confirm</Button>
                            <Button onClick={close}>Close</Button>
                        </div>
                    </>
                )}
            </Dialog>
        </div>
    )
}
