import {
    collection, deleteDoc, doc, DocumentData, getDocs, query,
    serverTimestamp, setDoc, where, updateDoc
} from "firebase/firestore";
import { firestore } from '../utils/Firebase';

const store = firestore();
const collectionRef = collection(store, 'projects');

export const AddProject = async (values: any) => {
    console.log("🚀 ~ AddProject ~ values:", values)
    const id = `PN-${Math.random().toString(10).slice(12).toUpperCase()}`;
    console.log(collectionRef.id);
    const q = query(collectionRef, where("projectNameEn", "==", values.projectNameEn));
    const dataToCreate = {
        ...values,
        id: id,
        createTimestamp: serverTimestamp(),
        updateTimestamp: serverTimestamp()
    }
    const docs = await getDocs(q);
    if (docs.size > 0) {
        return { code: 400, message: 'Project already exist' }
    } else {
        await setDoc(doc(collectionRef, id), dataToCreate);
        return { code: 200, message: 'Project addes successfully' }
    }

}

export const GetProjects = async () => {
    let projectList: DocumentData[] = [];
    const projects = await getDocs(collectionRef).then(
        (onfullFilled) => {
            onfullFilled.forEach(
                res => projectList.push(res.data())
            )
        },
        (onRejected) => {
            console.log(onRejected);
        }
    );
   
    // projects.forEach(
    //     res => projectList.push(res.data())
    // )
    return projectList;
}

export const DeleteProjects = async (selectedID: string[]) => {
    const q = query(collectionRef, where('id', 'in', selectedID));
    const res = await getDocs(q);
    res.forEach(async doc => await deleteDoc(doc.ref));
    return { code: 200, message: 'Project deleted successfully' }
}

export const UpdateProject = async (values: any) => {
    const docRef = doc(collectionRef, values.id)
    await updateDoc(docRef, values);
    return { code: 200, message: 'Project updated successfully' }
}

export const projectMetrics = async () => {
    const projectResponse = await getDocs(collectionRef);
    const q = query(collectionRef, where("status", "==", "Active"));
    const activeProjects = await getDocs(q);
    return {
        totalProjects: projectResponse.size,
        activeProjects: activeProjects.size
    }
}