import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../helpers/UseAuth';

export const PublicRoutes = () => {
    const auth = useAuth();
    // const token = await auth?.getIdToken();
    return auth?.user
        ? <Navigate to="/" />
        : <Outlet />
};



