import { Button, TextareaField, TextInputField, toaster } from 'evergreen-ui';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { ImagePicker } from '../components/ImagePicker';
import { PRODUCT_INITIAL_VAL } from '../constants/PRODUCTS';
import { ProductData } from '../models/Products';
import { AddProduct, UpdateProduct } from '../services/ProductService';
import '../styles.scss';
import { UploadFiles } from "../utils/Storage";

interface Props {
    setIsShown: (state: boolean) => void;
    editData?: any;
}

export const ProductAdd: FC<Props> = ({ setIsShown, editData }) => {
    const [values, setValues] = useState<ProductData>(PRODUCT_INITIAL_VAL);
    const [isLoading, setIsLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (!isEmpty(editData)) {
            setIsEdit(true);
            setValues(editData);
        }
    }, [editData]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const submitForm = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(values);
        if (!(['', null].some(item => Object.values(values).includes(item)))) {
            setFormValid(true);
            let res;
            const productCode = new Date().getTime().toString(36) + Math.random().toString(36).substring(2, 8);
            if (!isEdit) {
                const url = await UploadFiles(values.images, productCode);
                const thumbnailUrl = await UploadFiles(values.thumbnail, productCode);
                res = await AddProduct({ ...values, images: url, thumbnail: thumbnailUrl });
            } else {
                let payload = values;
                if (typeof values.images[0] !== 'string') {
                    const url = await UploadFiles(values.images, productCode);
                    payload = { ...payload, images: url };
                }

                if (typeof values.thumbnail[0] !== 'string') {
                    const thumbnailUrl = await UploadFiles(values.thumbnail, productCode);
                    payload = { ...payload, thumbnail: thumbnailUrl };
                }
                
                res = await UpdateProduct(payload);

            }

            if (res.code === 200) {
                toaster.success(res.message);
                setIsShown(false);
            } else {
                toaster.danger(res.message);
            }
        } else {
            setFormValid(false);
        }
        setIsLoading(false);
    }



    return (
        <div className='p-5'>
            <div className="text-xl">Add Product</div>
            <form onSubmit={submitForm}>
                <div className="grid grid-flow-row gap-2 grid-cols-2">
                    <TextInputField
                        label="Product Name"
                        placeholder="Enter product name"
                        name='productNameEn'
                        value={values.productNameEn}
                        onChange={handleInputChange}
                        isInvalid={values.productNameEn?.length === 0}
                        validationMessage={!formValid && values.productNameEn?.length === 0 && 'Invalid Product Code'}
                    />
                    <TextInputField
                        label="Product Name Arabic"
                        placeholder="Enter product name"
                        name='productNameAr'
                        value={values.productNameAr}
                        onChange={handleInputChange}
                        isInvalid={values.productNameAr?.length === 0}
                        validationMessage={!formValid && values.productNameAr?.length === 0 && 'Invalid Name'}
                    />
                    <TextareaField
                        label="Short Description"
                        placeholder="Enter product short description"
                        name='shortDescriptionEn'
                        value={values.shortDescriptionEn}
                        onChange={handleInputChange}
                        isInvalid={values.shortDescriptionEn?.length === 0}
                        validationMessage={!formValid && values.shortDescriptionEn?.length === 0 && 'Invalid Description'}
                    />
                    <TextareaField
                        label="Short Description Arabic"
                        placeholder="Enter product description"
                        name='shortDescriptionAr'
                        value={values.shortDescriptionAr}
                        onChange={handleInputChange}
                        isInvalid={values.shortDescriptionAr?.length === 0}
                        validationMessage={!formValid && values.shortDescriptionAr?.length === 0 && 'Invalid Description'}
                    />
                    <TextareaField
                        label="Description"
                        placeholder="Enter product description"
                        name='descriptionEn'
                        value={values.descriptionEn}
                        onChange={handleInputChange}
                        isInvalid={values.descriptionEn?.length === 0}
                        validationMessage={!formValid && values.descriptionEn?.length === 0 && 'Invalid Description'}
                    />
                    <TextareaField
                        label="Description Arabic"
                        placeholder="Enter product description"
                        name='descriptionAr'
                        value={values.descriptionAr}
                        onChange={handleInputChange}
                        isInvalid={values.descriptionAr?.length === 0}
                        validationMessage={!formValid && values.descriptionAr?.length === 0 && 'Invalid Description'}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='Thumbnail'
                        name='thumbnail'
                        images={values.thumbnail}
                        formValid={formValid}
                        multiple={false}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='Images'
                        name='images'
                        images={values.images}
                        formValid={formValid}
                        multiple={true}
                    />
                </div>
                <Button type='submit' className='w-full' appearance="primary" isLoading={isLoading}>{!isEdit ? 'Submit' : 'Update'}</Button>

            </form>
        </div>
    )
}
