import {
    collection, deleteDoc, doc, DocumentData, getDocs, query,
    serverTimestamp, setDoc, where, updateDoc
} from "firebase/firestore";
import { firestore } from '../utils/Firebase';

const store = firestore();
const collectionRef = collection(store, 'blogs');

export const AddBlog = async (values: any) => {
    console.log("🚀 ~ AddBlog ~ values:", values)
    const id = `PN-${Math.random().toString(10).slice(12).toUpperCase()}`;
    const q = query(collectionRef);
    const dataToCreate = {
        ...values,
        id: id,
        createTimestamp: serverTimestamp(),
        updateTimestamp: serverTimestamp()
    }
    await setDoc(doc(collectionRef, id), dataToCreate);
    return { code: 200, message: 'Blog addes successfully' }
}

export const GetBlogs = async () => {
    let blogList: DocumentData[] = [];
    const blogs = await getDocs(collectionRef).then(
        (onfullFilled) => {
            onfullFilled.forEach(
                res => blogList.push(res.data())
            )
        },
        (onRejected) => {
            console.log(onRejected);
        }
    );

    // blogs.forEach(
    //     res => blogList.push(res.data())
    // )
    return blogList;
}

export const DeleteBlogs = async (selectedID: string[]) => {
    const q = query(collectionRef, where('id', 'in', selectedID));
    const res = await getDocs(q);
    res.forEach(async doc => await deleteDoc(doc.ref));
    return { code: 200, message: 'Blog deleted successfully' }
}

export const UpdateBlog = async (values: any) => {
    const docRef = doc(collectionRef, values.id)
    await updateDoc(docRef, values);
    return { code: 200, message: 'Blog updated successfully' }
}