import { Button } from '@mui/material'
import { GridRowId, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarFilterButton } from '@mui/x-data-grid'
import React, { FC } from 'react'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { Link } from 'react-router-dom';

interface ToolbarProps {
    setIsShown: (state: boolean) => void;
    selectedID: GridRowId[];
    setShowDelete: (state: boolean) => void
}

export const BlogToolbar: FC<ToolbarProps> = ({ setIsShown, selectedID, setShowDelete }) => {
    return (
        <div className='p-6'>
            <div className="flex  justify-between">
                <div className='text-xl'>Blogs</div>
                <GridToolbarContainer>
                    <GridToolbarColumnsButton />
                    <GridToolbarFilterButton />
                    <Link to="/add-blog">
                        <Button size="small" color="primary" startIcon={<FiPlus />}>Add Blog</Button>
                    </Link>
                    {selectedID.length > 0 && <Button size="small" color="primary" startIcon={<FiTrash2 />} onClick={() => setShowDelete(true)}>Delete Blog(s)</Button>}
                </GridToolbarContainer>

            </div>
        </div>
    )
}
