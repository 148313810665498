import { DocumentData } from '@firebase/firestore';
import { Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { IconButton, SideSheet, majorScale, toaster } from 'evergreen-ui';
import { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { IoIosExpand } from 'react-icons/io';
import { ClientPartnersToolbar } from '../components/ClientsToolbar';
import { DeleteDialog } from '../containers/DeleteDialog';
import { DeleteClientPartners, GetClientPartners } from '../services/ClientPartnersService';
import { ClientPartnersAdd } from '../containers/ClientPartnersAdd';

export default function ClientPartners() {

    const [isShown, setIsShown] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [products, setClientPartners] = useState<DocumentData[]>([]);
    const [selectedID, setSelectedID] = useState<GridRowId[]>([]);
    const [editData, setEditData] = useState<GridRowModel>();
    const [isLoading, setIsLoading] = useState(true);
    const [showProuctInfo, setShowProductInfo] = useState(false);
    const [productInfo, setProductInfo] = useState<GridRowModel>()

    const columns: GridColDef[] = [
        {
            field: '',
            headerName: 'Actions',
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params: GridCellParams) => (
                <>

                    <Tooltip title='Edit Client'>
                        <IconButton icon={FiEdit2} intent="success" marginRight={majorScale(1)} onClick={() => onEditData(params)} />
                    </Tooltip>

                </>
            )
        },
        { field: 'id', width: 100, headerName: 'ID' },
        { field: 'name', width: 130, headerName: 'Client Name' },
        { field: 'type', width: 130, headerName: 'Client or Partner' },
        {
            field: 'thumbnail', width: 130, headerName: 'Thumbnail',
            renderCell: (params: GridCellParams) => (
                <img src={params.row.thumbnail[0] as string} alt='image' />
            )
        },
        { field: 'url', width: 400, headerName: 'URL' }
    ];

    const getData = async () => {
        let data = await GetClientPartners();
        console.log(data);
        setClientPartners(data);
        setIsLoading(false);
    };

    useEffect(() => { getData() }, [])

    const onEditData = (params: any) => {
        setEditData(params.row);
        setIsShown(true)
    }

    const onOpenDetails = (params: any) => {
        setShowProductInfo(true);
        setProductInfo(params.row);
    }

    const onSheetClose = () => {
        setIsShown(false);
        setEditData({});
        getData();
    }

    const deleteRecord = async () => {
        console.log('Delete selected records', selectedID);
        const res = await DeleteClientPartners(selectedID as string[]);
        if (res.code === 200) {
            toaster.success(res.message);
        } else {
            toaster.danger(res.message);
        }
        setShowDelete(false);
        getData();
    }

    return (
        <div className="px-10 pt-5">
            <SideSheet isShown={isShown} onCloseComplete={onSheetClose} shouldCloseOnOverlayClick={false}>
                <ClientPartnersAdd setIsShown={onSheetClose} editData={editData} />
            </SideSheet>
            <DeleteDialog showDelete={showDelete} setShowDelete={setShowDelete} deleteRecord={deleteRecord} />
            {/* <CliDetails isShown={showProuctInfo} setIsShown={() => setShowProductInfo(false)} productInfo={productInfo} /> */}
            <div className='h-100 w-full bg-white' >
                <DataGrid
                    loading={isLoading}
                    rows={products}
                    columns={columns}
                    components={{ Toolbar: ClientPartnersToolbar}}
                    componentsProps={{
                        toolbar: { setIsShown, selectedID, setShowDelete }
                    }}
                    onSelectionModelChange={(newSelectionModel) => { setSelectedID(newSelectionModel) }}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                />
            </div>
        </div >
    );
}
