import { Button, TextInputField, toaster } from 'evergreen-ui';
import { useState } from 'react';
import { Editor, EditorState } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { ImagePicker } from '../components/ImagePicker';
import { AddBlog, UpdateBlog } from '../services/BlogService';
import { UploadFiles } from '../utils/Storage';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const AddBlogComponent = () => {
    const [editorStateEn, setEditorStateEn] = useState<any>();
    const [editorStateAr, setEditorStateAr] = useState<any>();
    const [values, setValues] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    // useEffect(() => {
    //     if (!isEmpty(editData)) {
    //         setIsEdit(true);
    //         setValues(editData);
    //     }
    // }, [editData]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const handleEditorStateChange = (newEditorState: EditorState, key: string) => {
        if (key === 'contentEn') {
            setEditorStateEn(newEditorState);
        } else {
            setEditorStateAr(newEditorState);
        }
        setValues({
            ...values,
            [key]: draftToHtml(convertToRaw(newEditorState.getCurrentContent()))
        })
    };

    const submitForm = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(values);
        if (!(['', null].some(item => Object.values(values).includes(item)))) {
            setFormValid(true);
            let res;
            const blogID = 'blog-' + new Date().getTime().toString(36) + Math.random().toString(36).substring(2, 8);
            if (!isEdit) {
                const url = await UploadFiles(values.images, blogID);
                const pdfUrl = await UploadFiles(values.pdf, blogID);
                res = await AddBlog({ ...values, images: url, pdf: pdfUrl });
            } else {
                let payload = values;
                if (typeof values.images[0] !== 'string') {
                    const url = await UploadFiles(values.images, blogID);
                    payload = { ...payload, images: url };
                }
                if (typeof values.pdf[0] !== 'string') {
                    const pdfUrl = await UploadFiles(values.pdf, blogID);
                    payload = { ...payload, pdf: pdfUrl };
                }

                res = await UpdateBlog(payload);
            }

            if (res.code === 200) {
                toaster.success(res.message);
            } else {
                toaster.danger(res.message);
            }
        } else {
            setFormValid(false);
        }
        setIsLoading(false);
    }

    return (
        <div className='m-5'>
            <form onSubmit={submitForm}>
                <div className="grid grid-flow-row gap-2 grid-cols-2">

                    <TextInputField
                        label="Blog Title English"
                        placeholder="Enter blog title"
                        name='blogTitleEn'
                        value={values.blogTitleEn}
                        onChange={handleInputChange}
                        isInvalid={values.blogTitleEn?.length === 0}
                        validationMessage={!formValid && values.blogTitleEn?.length === 0 && 'Enter blog title'}
                    />
                    <TextInputField
                        label="Blog Title Arabic"
                        placeholder="Enter blog title"
                        name='blogTitleAr'
                        value={values.blogTitleAr}
                        onChange={handleInputChange}
                        isInvalid={values.blogTitleAr?.length === 0}
                        validationMessage={!formValid && values.blogTitleAr?.length === 0 && 'Enter blog title'}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='Images'
                        name='images'
                        images={values.images}
                        formValid={formValid}
                        multiple={false}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='PDF'
                        name='pdf'
                        format={['application/pdf']}
                        images={values.pdf}
                        formValid={formValid}
                        multiple={false}
                    />
                </div>
                English
                <Editor
                    editorState={editorStateEn}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName=""
                    editorClassName="bg-white p-2"
                    onEditorStateChange={(state) => handleEditorStateChange(state, 'contentEn')}
                />
                Arabic
                <Editor
                    editorState={editorStateAr}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName=""
                    editorClassName="bg-white p-2"
                    onEditorStateChange={(state) => handleEditorStateChange(state, 'contentAr')}
                />
                <Button type='submit' className='w-full' appearance="primary" isLoading={isLoading}>{!isEdit ? 'Submit' : 'Update'}</Button>
            </form>
        </div>
    );
}

export default AddBlogComponent