import { DocumentData } from '@firebase/firestore';
import { Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { IconButton, majorScale, SideSheet, toaster } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { IoIosExpand } from 'react-icons/io';
import { ProductToolbar } from '../components/ProductToolbar';
import { DeleteDialog } from '../containers/DeleteDialog';
import { ProductAdd } from '../containers/ProductAdd';
import { ProductDetails } from '../containers/ProductDetails';
import { DeleteProducts, GetProducts } from '../services/ProductService';

export default function Products() {

    const [isShown, setIsShown] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [products, setProducts] = useState<DocumentData[]>([]);
    const [selectedID, setSelectedID] = useState<GridRowId[]>([]);
    const [editData, setEditData] = useState<GridRowModel>();
    const [isLoading, setIsLoading] = useState(true);
    const [showProuctInfo, setShowProductInfo] = useState(false);
    const [productInfo, setProductInfo] = useState<GridRowModel>()

    const columns: GridColDef[] = [
        {
            field: '',
            headerName: 'Actions',
            disableColumnMenu: true,
            hideSortIcons: true,
            renderCell: (params: GridCellParams) => (
                <>

                    <Tooltip title='Edit Product'>
                        <IconButton icon={FiEdit2} intent="success" marginRight={majorScale(1)} onClick={() => onEditData(params)} />
                    </Tooltip>
                    <Tooltip title='Open Product'>
                        <IconButton icon={IoIosExpand} intent="success" marginRight={majorScale(1)} onClick={() => onOpenDetails(params)} />
                    </Tooltip>

                </>
            )
        },
        { field: 'id', width: 100, headerName: 'ID' },
        { field: 'productNameEn', width: 130, headerName: 'Product Name' },
        { field: 'productNameAr', width: 200, headerName: 'Product Name Arabic' },
        {
            field: 'thumbnail', width: 130, headerName: 'Thumbnail',
            renderCell: (params: GridCellParams) => (
                <img src={params.row.thumbnail[0] as string} alt='image' />
            )
        },
        {
            field: 'shortDescriptionEn', width: 130, headerName: 'Short Description',
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.shortDescriptionEn} >
                    <span className="table-cell-trucate">{params.row.shortDescriptionEn}</span>
                </Tooltip>
            )
        },
        {
            field: 'shortDescriptionAr', width: 130, headerName: 'Short Description Arabic',
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.shortDescriptionAr} >
                    <span className="table-cell-trucate">{params.row.shortDescriptionAr}</span>
                </Tooltip>
            )
        },
        {
            field: 'descriptionEn', width: 130, headerName: 'Description',
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.descriptionEn} >
                    <span className="table-cell-trucate">{params.row.descriptionEn}</span>
                </Tooltip>
            )
        },
        {
            field: 'descriptionAr', width: 200, headerName: 'Description Arabic',
            renderCell: (params: GridCellParams) => (
                <Tooltip title={params.row.descriptionAr} >
                    <span className="table-cell-trucate">{params.row.descriptionAr}</span>
                </Tooltip>
            )
        }
    ];

    const getData = async () => {
        let data = await GetProducts();
        console.log(data);
        setProducts(data);
        setIsLoading(false);
    };

    useEffect(() => { getData() }, [])

    const onEditData = (params: any) => {
        setEditData(params.row);
        setIsShown(true)
    }

    const onOpenDetails = (params: any) => {
        setShowProductInfo(true);
        setProductInfo(params.row);
    }

    const onSheetClose = () => {
        setIsShown(false);
        setEditData({});
        getData();
    }

    const deleteRecord = async () => {
        console.log('Delete selected records', selectedID);
        const res = await DeleteProducts(selectedID as string[]);
        if (res.code === 200) {
            toaster.success(res.message);
        } else {
            toaster.danger(res.message);
        }
        setShowDelete(false);
        getData();
    }

    return (
        <div className="px-10 pt-5">
            <SideSheet isShown={isShown} onCloseComplete={onSheetClose} shouldCloseOnOverlayClick={false}>
                <ProductAdd setIsShown={onSheetClose} editData={editData} />
            </SideSheet>
            <DeleteDialog showDelete={showDelete} setShowDelete={setShowDelete} deleteRecord={deleteRecord} />
            <ProductDetails isShown={showProuctInfo} setIsShown={() => setShowProductInfo(false)} productInfo={productInfo} />
            <div className='h-100 w-full bg-white' >
                <DataGrid
                    loading={isLoading}
                    rows={products}
                    columns={columns}
                    components={{ Toolbar: ProductToolbar }}
                    componentsProps={{
                        toolbar: { setIsShown, selectedID, setShowDelete }
                    }}
                    onSelectionModelChange={(newSelectionModel) => { setSelectedID(newSelectionModel) }}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                />
            </div>
        </div >
    );
}
