import { FilePicker } from 'evergreen-ui'
import { BsFillExclamationCircleFill } from 'react-icons/bs'
import React, { FC } from 'react'

interface Props {
    images: any;
    label: string;
    multiple: boolean;
    format?: any;
    name: string;
    formValid: boolean;
    handleInputChange: (data: any) => void
}

export const ImagePicker: FC<Props> = ({ images, label, multiple, format, name, handleInputChange, formValid }) => {
    return (
        <div className='mb-2'>
            <div className='mb-2 text-sm font-medium'>{label}</div>
            <FilePicker
                multiple={multiple}
                accept={format ||['image/png', 'image/jpeg', 'image/jpg']}
                onChange={(files: FileList) =>
                    handleInputChange({ target: { name: name, value: files } })}
                name={name}
                placeholder="Select the file!"
            />
            {!formValid && !images &&
                <>
                    <BsFillExclamationCircleFill display='inline' color='#D14343' size='16px' style={{ marginRight: '8px', display: 'inline' }} />
                    <p style={{ color: '#D14343', lineHeight: '18px', fontSize: '12px', display: 'inline' }}>No Images selected</p>

                </>
            }
        </div>
    )
}
