import { DocumentData } from '@firebase/firestore';
import { Tooltip } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridRowId, GridRowModel } from '@mui/x-data-grid';
import { IconButton, majorScale, SideSheet, toaster } from 'evergreen-ui';
import React, { useEffect, useState } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { IoIosExpand } from 'react-icons/io';
import { BlogToolbar } from '../components/BlogToolbar';
import { DeleteDialog } from '../containers/DeleteDialog';
import AddBlog  from './AddBlogComponent';
// import { BlogDetails } from '../containers/BlogDetails';
import { DeleteBlogs, GetBlogs } from '../services/BlogService';

export default function Blogs() {

    const [isShown, setIsShown] = useState(false);
    const [showDelete, setShowDelete] = useState(false);
    const [blogs, setBlogs] = useState<DocumentData[]>([]);
    const [selectedID, setSelectedID] = useState<GridRowId[]>([]);
    const [editData, setEditData] = useState<GridRowModel>();
    const [isLoading, setIsLoading] = useState(true);
    const [showProuctInfo, setShowBlogInfo] = useState(false);
    const [blogInfo, setBlogInfo] = useState<GridRowModel>()

    const columns: GridColDef[] = [
        // {
        //     field: '',
        //     headerName: 'Actions',
        //     disableColumnMenu: true,
        //     hideSortIcons: true,
        //     renderCell: (params: GridCellParams) => (
        //         <>

        //             <Tooltip title='Edit Blog'>
        //                 <IconButton icon={FiEdit2} intent="success" marginRight={majorScale(1)} onClick={() => onEditData(params)} />
        //             </Tooltip>
        //             {/* <Tooltip title='Open Blog'>
        //                 <IconButton icon={IoIosExpand} intent="success" marginRight={majorScale(1)} onClick={() => onOpenDetails(params)} />
        //             </Tooltip> */}

        //         </>
        //     )
        // },
        { field: 'id', width: 100, headerName: 'ID' },
        { field: 'blogTitleAr', width: 130, headerName: 'Blog Title Arabic' },
        { field: 'blogTitleEn', width: 130, headerName: 'Blog Title English' }
    ];

    const getData = async () => {
        let data = await GetBlogs();
        console.log(data);
        setBlogs(data);
        setIsLoading(false);
    };

    useEffect(() => { getData() }, [])

    const onEditData = (params: any) => {
        setEditData(params.row);
        setIsShown(true)
    }

    const onOpenDetails = (params: any) => {
        setShowBlogInfo(true);
        setBlogInfo(params.row);
    }

    const onSheetClose = () => {
        setIsShown(false);
        setEditData({});
        getData();
    }

    const deleteRecord = async () => {
        console.log('Delete selected records', selectedID);
        const res = await DeleteBlogs(selectedID as string[]);
        if (res.code === 200) {
            toaster.success(res.message);
        } else {
            toaster.danger(res.message);
        }
        setShowDelete(false);
        getData();
    }

    return (
        <div className="px-10 pt-5">
            
            <DeleteDialog showDelete={showDelete} setShowDelete={setShowDelete} deleteRecord={deleteRecord} />
            {/* <BlogDetails isShown={showProuctInfo} setIsShown={() => setShowBlogInfo(false)} blogInfo={blogInfo} /> */}
            <div className='h-100 w-full bg-white' >
                <DataGrid
                    loading={isLoading}
                    rows={blogs}
                    columns={columns}
                    components={{ Toolbar: BlogToolbar }}
                    componentsProps={{
                        toolbar: { setIsShown, selectedID, setShowDelete }
                    }}
                    onSelectionModelChange={(newSelectionModel) => { setSelectedID(newSelectionModel) }}
                    pageSize={5}
                    rowsPerPageOptions={[5]}
                    checkboxSelection
                    disableSelectionOnClick
                />
            </div>
        </div >
    );
}
