import { Button, TextareaField, TextInputField, toaster } from 'evergreen-ui';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { ImagePicker } from '../components/ImagePicker';
import { PROJECT_INITIAL_VAL } from '../constants/PROJECTS';
import { ProjectData } from '../models/Projects';
import { AddProject, UpdateProject } from '../services/ProjectService';
import '../styles.scss';
import { UploadFiles } from "../utils/Storage";

interface Props {
    setIsShown: (state: boolean) => void;
    editData?: any;
}

export const ProjectAdd: FC<Props> = ({ setIsShown, editData }) => {
    const [values, setValues] = useState<ProjectData>(PROJECT_INITIAL_VAL);
    const [isLoading, setIsLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (!isEmpty(editData)) {
            setIsEdit(true);
            setValues(editData);
        }
    }, [editData]);

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const submitForm = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(values);
        if (!(['', null].some(item => Object.values(values).includes(item)))) {
            console.log('Form Valid');
            setFormValid(true);
            let res;
            const projectCode = new Date().getTime().toString(36) + Math.random().toString(36).substring(2, 8);
            if (!isEdit) {
                const url = await UploadFiles(values.images, projectCode);
                res = await AddProject({ ...values, images: url});
            } else {
                let payload = values;
                if (typeof values.images[0] !== 'string') {
                    const url = await UploadFiles(values.images, projectCode);
                    payload = { ...payload, images: url };
                }
                
                res = await UpdateProject(payload);

            }

            if (res.code === 200) {
                toaster.success(res.message);
                setIsShown(false);
            } else {
                toaster.danger(res.message);
            }
        } else {
            console.log('Form Invalid');
            setFormValid(false);
        }
        setIsLoading(false);
    }



    return (
        <div className='p-5'>
            <div className="text-xl">Add Project</div>
            <form onSubmit={submitForm}>
                <div className="grid grid-flow-row gap-2 grid-cols-2">
                    <TextInputField
                        label="Project Name"
                        placeholder="Enter project name"
                        name='projectNameEn'
                        value={values.projectNameEn}
                        onChange={handleInputChange}
                        isInvalid={values.projectNameEn?.length === 0}
                        validationMessage={!formValid && values.projectNameEn?.length === 0 && 'Invalid Name'}
                    />
                    <TextInputField
                        label="Project Name Arabic"
                        placeholder="Enter project name in arabic"
                        name='projectNameAr'
                        value={values.projectNameAr}
                        onChange={handleInputChange}
                        isInvalid={values.projectNameAr?.length === 0}
                        validationMessage={!formValid && values.projectNameAr?.length === 0 && 'Invalid Name'}
                    />
                    <TextareaField
                        label="Description"
                        placeholder="Enter project description"
                        name='descriptionEn'
                        value={values.descriptionEn}
                        onChange={handleInputChange}
                        isInvalid={values.descriptionEn?.length === 0}
                        validationMessage={!formValid && values.descriptionEn?.length === 0 && 'Invalid Description'}
                    />
                    <TextareaField
                        label="Description Arabic"
                        placeholder="Enter project description in arabic"
                        name='descriptionAr'
                        value={values.descriptionAr}
                        onChange={handleInputChange}
                        isInvalid={values.descriptionAr?.length === 0}
                        validationMessage={!formValid && values.descriptionAr?.length === 0 && 'Invalid Description'}
                    />
                    <TextInputField
                        label="Client"
                        placeholder="Enter client name"
                        name='clientEn'
                        value={values.clientEn}
                        onChange={handleInputChange}
                        isInvalid={values.clientEn?.length === 0}
                        validationMessage={!formValid && values.clientEn?.length === 0 && 'Invalid Client Name'}
                    />
                    <TextInputField
                        label="Client Arabic"
                        placeholder="Enter client name in arabic"
                        name='clientAr'
                        value={values.clientAr}
                        onChange={handleInputChange}
                        isInvalid={values.clientAr?.length === 0}
                        validationMessage={!formValid && values.clientAr?.length === 0 && 'Invalid Client Name'}
                    />
                    <TextInputField
                        label="Location"
                        placeholder="Enter location"
                        name='locationEn'
                        value={values.locationEn}
                        onChange={handleInputChange}
                        isInvalid={values.locationEn?.length === 0}
                        validationMessage={!formValid && values.locationEn?.length === 0 && 'Invalid Location'}
                    />
                    <TextInputField
                        label="Location Arabic"
                        placeholder="Enter location in arabic"
                        name='locationAr'
                        value={values.locationAr}
                        onChange={handleInputChange}
                        isInvalid={values.locationAr?.length === 0}
                        validationMessage={!formValid && values.locationAr?.length === 0 && 'Invalid Location'}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='Images'
                        name='images'
                        images={values.images}
                        formValid={formValid}
                        multiple={true}
                    />
                </div>
                <Button type='submit' className='w-full' appearance="primary" isLoading={isLoading}>{!isEdit ? 'Submit' : 'Update'}</Button>

            </form>
        </div>
    )
}
