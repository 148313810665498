import { onAuthStateChanged, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { useEffect, useState } from "react";
import { firebaseAuth } from "../utils/Firebase";
import { AuthContext } from "./AuthContext";

export interface IAuthProvider {
    signIn: (emailAddress: string, password: string) => Promise<any>;
    logOut: () => Promise<void>;
    user: any;
}

export const AuthProvider: React.FC = ({ children }) => {
    const auth = firebaseAuth();
    const userType = auth.currentUser;

    const [user, setUser] = useState<typeof userType | null>(null);
    const [loading, setLoading] = useState(true)

    const signIn = async (emailAddress: string, password: string) => await signInWithEmailAndPassword(auth, emailAddress, password);

    const logOut = async () => await signOut(auth);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (firebaseUser) => {
            setUser(firebaseUser);
            setLoading(false)
        });
        return unsubscribe;
    }, []);

    const value = {
        signIn,
        logOut,
        user
    }

    return <AuthContext.Provider value={value}> {!loading && children} </AuthContext.Provider>;
};

export default AuthProvider;