import { Button, RadioGroup, TextInputField, toaster } from 'evergreen-ui';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { ImagePicker } from '../components/ImagePicker';
import { CLIENTS_INITIAL_VAL } from '../constants/CLIENTS';
import { ClientPartnersData } from '../models/ClientPartners';
import { AddClientPartners, UpdateClientPartners } from '../services/ClientPartnersService';
import '../styles.scss';
import { UploadFiles } from "../utils/Storage";

interface Props {
    setIsShown: (state: boolean) => void;
    editData?: any;
}

export const ClientPartnersAdd: FC<Props> = ({ setIsShown, editData }) => {
    const [values, setValues] = useState<ClientPartnersData>(CLIENTS_INITIAL_VAL);
    const [isLoading, setIsLoading] = useState(false);
    const [formValid, setFormValid] = useState(true);
    const [isEdit, setIsEdit] = useState(false);

    useEffect(() => {
        if (!isEmpty(editData)) {
            setIsEdit(true);
            setValues(editData);
        }
    }, [editData]);

    const handleInputChange = (e: any) => {
        console.log("🚀 ~ handleInputChange ~ e:", e)
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
        });
    };

    const submitForm = async (e: any) => {
        setIsLoading(true);
        e.preventDefault();
        console.log(values);
        if (!(['', null].some(item => Object.values(values).includes(item)))) {
            setFormValid(true);
            let res;
            const productCode = new Date().getTime().toString(36) + Math.random().toString(36).substring(2, 8);
            if (!isEdit) {
                const thumbnailUrl = await UploadFiles(values.thumbnail, productCode);
                res = await AddClientPartners({ ...values, thumbnail: thumbnailUrl });
            } else {
                let payload = values;

                if (typeof values.thumbnail[0] !== 'string') {
                    const thumbnailUrl = await UploadFiles(values.thumbnail, productCode);
                    payload = { ...payload, thumbnail: thumbnailUrl };
                }

                res = await UpdateClientPartners(payload);

            }

            if (res.code === 200) {
                toaster.success(res.message);
                setIsShown(false);
            } else {
                toaster.danger(res.message);
            }
        } else {
            setFormValid(false);
        }
        setIsLoading(false);
    }



    return (
        <div className='p-5'>
            <div className="text-xl">Add Client or Partner</div>
            <form onSubmit={submitForm}>
                <div className="grid grid-flow-row gap-2 grid-cols-2">
                    <TextInputField
                        label="Client Name"
                        placeholder="Enter client name"
                        name='name'
                        value={values.name}
                        onChange={handleInputChange}
                        isInvalid={values.name?.length === 0}
                        validationMessage={!formValid && values.name?.length === 0 && 'Invalid Name'}
                    />
                    <RadioGroup
                        label="Client or Partner"
                        title='type'
                        value={values.type}
                        options={[{ label: 'Client', value: 'client' }, { label: 'Partner', value: 'partner' }]}
                        onChange={event => handleInputChange({ target: { name: 'type', value: event.target.value } })}
                    />
                    <TextInputField
                        label="URL"
                        placeholder="Enter url"
                        name='url'
                        value={values.url}
                        onChange={handleInputChange}
                        isInvalid={values.url?.length === 0}
                        validationMessage={!formValid && values.url?.length === 0 && 'Invalid URL'}
                    />
                    <ImagePicker
                        handleInputChange={handleInputChange}
                        label='Images'
                        name='thumbnail'
                        images={values.thumbnail}
                        formValid={formValid}
                        multiple={false}
                    />
                </div>
                <Button type='submit' className='w-full' appearance="primary" isLoading={isLoading}>{!isEdit ? 'Submit' : 'Update'}</Button>

            </form>
        </div>
    )
}
