import { initializeApp, getApps } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_ID,
    appId: process.env.REACT_APP_APP_ID
};

export const firebaseAuth = () => {
    if (!getApps().length) {
        const firebaseApp = initializeApp(firebaseConfig);
        return getAuth(firebaseApp);
    }
    return getAuth();
}

export const firestore = () => {
    if (!getApps().length) {
        const firebaseApp = initializeApp(firebaseConfig);
        return getFirestore(firebaseApp);
    }
    return getFirestore();
}

export const firestorage = () => {
    if (!getApps().length) {
        const firebaseApp = initializeApp(firebaseConfig);
        return getStorage(firebaseApp);
    }
    return getStorage();
}


