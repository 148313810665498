export const PROJECT_INITIAL_VAL = {
    projectNameEn: '',
    projectNameAr: '',
    descriptionEn: '',
    descriptionAr: '',
    clientEn: '',
    clientAr: '',
    locationEn: '',
    locationAr: '',
    images: null,
}